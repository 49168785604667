:root {
    --indicator-sizing-small: calc(var(--base-unit) * 5);
    --indicator-sizing-large: calc(var(--base-unit) * 18);
}

.root {
    display: inline-flex;
    align-items: center;
    height: calc(var(--base-unit) * 11);
}

.indicatorInfinite {
    position: relative;
    height: 2px;
    overflow: hidden;
    background: var(--border-light-color);
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: currentColor;
        animation-name: progress;
        animation-duration: 1s;
        animation-timing-function: var(--animation-curve);
        animation-iteration-count: infinite;
        @media (prefers-reduced-motion) {
            animation: none;
        }
    }
}

.indicatorLinear {
    position: relative;
    height: 2px;
    overflow: hidden;
    background: var(--border-light-color);
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 95%;
        height: 100%;
        background: currentColor;
        animation: linearProgress 6s;
        animation-timing-function: var(--animation-curve);
        @media (prefers-reduced-motion) {
            animation: none;
        }
    }
}

.small .indicatorSizing {
    width: var(--indicator-sizing-small);
    margin-right: var(--spacing-2);
}

.large {
    flex-direction: column;

    .indicatorSizing {
        width: var(--indicator-sizing-large);
        margin-bottom: var(--spacing-2);
    }
}

@keyframes progress {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

@keyframes linearProgress {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

:global(.mcds-redesign-organisms) {
    .indicatorInfinite {
        height: calc(var(--base-unit) * 0.5);
        &::before {
            content: '';
            background: var(--color-ui-secondary);
        }
    }

    .indicatorLinear {
        height: calc(var(--base-unit) * 0.5);
        &::before {
            content: '';
            background: var(--color-ui-secondary);
        }
    }
    .primary {
        background: var(--color-track-primary);
    }

    .secondary {
        background: var(--color-track-secondary);
    }

    .storyContainer {
        background-color: var(--color-container-background-secondary);
    }
}
